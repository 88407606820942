import React from "react";
import { Row, Col } from "antd";
import Dolar from "./dolar";
import BinancePriceStatistics from "./binance/statistics";
import BinanceSelling from "./binance/selling";
import BinancePurchase from "./binance/purchase";
import LemonCashStatistics from "./lemoncash";

const style = { padding: '10px' };

const Dashboard = () => (
    <Row>
        <Col xs={{span: 24}} sm={{span: 6}}>
            <div style={style}><Dolar /></div>
        </Col>
        <Col xs={{span: 24}} sm={{span: 6}}>
            <div style={style}><BinancePriceStatistics /></div>
        </Col>
        <Col xs={{span: 24}} sm={{span: 6}}>
            <div style={style}><BinancePurchase /></div>
        </Col>
        <Col xs={{span: 24}} sm={{span: 6}}>
            <div style={style}><LemonCashStatistics /></div>
        </Col>
        <Col lg={{span: 18}} sm={{span: 24}} xs={{span: 24}}>
            <div style={style}><BinanceSelling /></div>
        </Col>
    </Row>
)

export default Dashboard