import React, { useState, useEffect} from "react";
import axios from 'axios';
import { Statistic, Card } from "antd";

const Dolar = () => {
    const [currentValue, setCurrentValue] = useState(0);
    
    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/dolar/blue`).then( response => {
            setCurrentValue(response.data.value);
        })
    }, []);

    return (
        <Card>
          <Statistic
            title="Dolar Blue"
            value={currentValue}
            precision={2}
            prefix={'$'}
            valueStyle={{ color: '#3f8600' }}
            
          />
        </Card>
    );
};

export default Dolar;