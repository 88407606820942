import { useState, useEffect} from "react";
import axios from 'axios';
import { Statistic, Card } from "antd";

const BinancePurchase = () => {
    const [price, setPrice] = useState(0);

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/binance/prices/purchase`)
        .then( response => setPrice(response.data.price))
    }, []);

    return (
        <Card>
          <Statistic
            title="Compra"
            value={price}
            precision={3}
            prefix={'$'}
            valueStyle={{ color: '#3f8600' }}
          />
        </Card>
    )
}

export default BinancePurchase