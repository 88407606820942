import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card, Statistic } from 'antd';

const LemonCashStatistics = () => {
    const [price, setPrice] = useState(0);
    useEffect( () => {
        axios.get('https://criptoya.com/api/lemoncash/dai').then(
            response => setPrice(response.data.bid)
        )
    }, []);

    return (
        <Card>
          <Statistic
            title="Dolar LemonCash"
            value={price}
            precision={2}
            prefix={'$'}
            valueStyle={{ color: '#3f8600' }}
          />
        </Card>
    )
}

export default LemonCashStatistics