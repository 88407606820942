import "./App.css";
import "antd/dist/antd.min.css";
import AppLayout from './Layout';

function App() {
  return (
    <div className="App">
      <AppLayout />
    </div>
  );
}

export default App;
