import React from 'react';
// import 'antd/dist/antd.css';
// import './index.css';
import { Layout, Menu } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import Dashboard from './dashboard';

const { Header, Content, Footer, Sider } = Layout;

const AppLayout = () => (
  <Layout>
    <Sider
      style={{minHeight: "100vh"}}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="logo">Finadmin</div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['4']}
        items={[UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
          (icon, index) => ({
            key: String(index + 1),
            icon: React.createElement(icon),
            label: `nav ${index + 1}`,
          }),
        )}
      />
    </Sider>
    <Layout>
      <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
      <Content style={{ margin: '24px 16px 0' }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: '75vh' }}>
          <Dashboard />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>{process.env.REACT_APP_NAME}</Footer>
    </Layout>
  </Layout>
);

export default AppLayout;