import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Statistic, Card } from "antd";

const BinancePriceStatistics = () => {
    const [averagePrice, setAveragePrice] = useState([])
    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/binance/prices/selling`)
            .then( response => {
                var binancePrices = response.data.map(d => d.prices);
                var allPrices = binancePrices.reduce((previus, current) => ([...previus, ...current]), [])
                const sum = allPrices.reduce( (a, b) => a+b, 0);
                const average = sum / allPrices.length;
                setAveragePrice(average);
            });
    }, []);

    return (
        <Card>
          <Statistic
            title="Promedio Venta"
            value={averagePrice}
            precision={2}
            prefix={'$'}
            valueStyle={{ color: '#3f8600' }}
          />
        </Card>
    )
}

export default BinancePriceStatistics