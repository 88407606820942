import React, { useState, useEffect} from "react";
import { Table } from 'antd';
import axios from 'axios';

const BinanceSelling = () => {
    const [prices, setPrices] = useState([]);

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/binance/prices/selling`)
            .then( response => setPrices(response.data));
    }, []);

    const formatter = { format: d => (Math.round(d * 100) / 100).toFixed(2) }

    const columns = [
        {
            title: 'Forma de Pago',
            dataIndex: 'payType',
            key: 'payType',
            responsive: ['xs', 'sm']
        },
        {
            title: 'Mínimo',
            dataIndex: 'min',
            key: 'min',
            render: text => formatter.format(text),
            align: 'right',
            sorter: (a, b) => a.min - b.min,
            responsive: ['sm']
        },
        {
            title: 'Máximo',
            dataIndex: 'max',
            key: 'max',
            render: text => formatter.format(text),
            align: 'right',
            sorter: (a, b) => a.max - b.max
        },
        {
            title: 'Promedio',
            dataIndex: 'avg',
            key: 'avg',
            render: text => formatter.format(text),
            align: 'right',
            sorter: (a, b) => a.avg - b.avg,
            responsive: ['sm']
        }

    ]

    return (
        <Table columns={columns} dataSource={prices} size="small" bordered pagination={false} />
    )
}

export default BinanceSelling